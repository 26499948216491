import React from 'react'
import { Formik } from 'formik'
import { ModalFooter, ModalBody, ModalHeader, Modal, Button } from 'reactstrap'
import ListTable from '../components/ListTable/ListTable'
import DashboardLayout from '../components/Layout/DashboardLayout'
import { UserContext } from '../contexts'
import * as userForm from '../forms/userForm'
import FormikInputGenerator from '../components/Form/FormikInputGenerator'
import { toast } from 'react-toastify'
import { createUser, fetchUsers, updateUser } from '../requests/users'

export default class Users extends React.Component {
  static contextType = UserContext

  state = {
    formModalOpen: false,
    user: {},
    users: []
  }

  componentDidMount () {
    this.refreshUsers()
  }

  refreshUsers = () => {
    const { token } = this.context

    fetchUsers(token).then(res => this.setState({
      users: res.map(user => ({
        ...user,
        fullName: `${user.firstNames} ${user.lastNames}`
      }))
    }))
  }

  handleEdit = (user) => {
    this.setState({ user })
    this.toggleFormModal()
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { token } = this.context
    const { user } = this.state

    if (user._id) {
      updateUser(token, user._id, form)
        .then(this.handleResult('Se ha actualizado la empresa', setSubmitting))
    } else {
      form = { ...form, password: 'cambiame' }

      createUser(token, form)
        .then(this.handleResult('Se ha creado la empresa', setSubmitting))
    }
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshUsers()
      toast.success(message)
    }
  }

  toggleFormModal = () => {
    if (this.state.formModalOpen) {
      this.setState({ user: {} })
    }
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  render () {
    return (
      <DashboardLayout companyId={this.props.match.params.companyId} heading="Usuarios">
        <Button onClick={this.toggleFormModal} size="sm">+ Agregar usuario</Button>

        <p/>

        <ListTable data={this.state.users} onEdit={this.handleEdit} columns={[
          { key: 'rut', display: 'RUT' },
          { key: 'fullName', display: 'Nombre' },
          { key: 'phone', display: 'Teléfono' },
          { key: 'gender', display: 'Género' },
          { key: 'birthDate', display: 'Fecha de nacimiento' },
          { key: 'nationality', display: 'Nacionalidad' },
        ]}/>

        {this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderFormModal () {
    const { formModalOpen, user } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Usuario</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={userForm.validation}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInputGenerator fields={userForm.fields} formikProps={props}/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}
