import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Formik } from 'formik'
import ButtonNavigation from '../components/ButtonNavigation/ButtonNavigation'
import FormikInput from '../components/Form/FormikInput'
import { UserContext } from '../contexts'
import DashboardLayout from '../components/Layout/DashboardLayout'
import { toast } from 'react-toastify'
import { fetchCompany, fetchIsAdmin, updateCompany, updateCompanyPhoto } from '../requests/companies'
import * as companyForm from '../forms/companyForm'

class CompanyDetail extends React.Component {
  static contextType = UserContext

  state = {
    company: {},
    canAdmin: false,
  }

  componentDidMount () {
    this.refreshCompany()
    this.refreshCanAdmin()
  }

  refreshCanAdmin = () => {
    const { companyId } = this.props.match.params
    const { token, bundle } = this.context

    if (bundle.isAdmin === true) {
      this.setState({ canAdmin: bundle.isAdmin })
    } else {
      fetchIsAdmin(token, companyId, bundle._id)
        .then(canAdmin => this.setState({ canAdmin }))
    }
  }

  refreshCompany = () => {
    const { token } = this.context
    const { companyId } = this.props.match.params

    fetchCompany(token, companyId).then(res => {
      this.setState({ company: res })
    })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props.match.params

    updateCompany(this.context.token, companyId, form)
      .then(res => {
        setSubmitting(false)
        this.setState({ company: res })
        toast.success('Se guardaron los cambios')
      })
  }

  handleFileChange = (e) => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]
    this.setState({ file })
  }

  handleSubmitPhoto = () => {
    const { file } = this.state
    const { token } = this.context
    const { companyId } = this.props.match.params

    updateCompanyPhoto(token, companyId, file)
      .then(res => {
        this.refreshCompany()
      })
  }

  render () {
    const { token } = this.context
    const { canAdmin, company } = this.state

    return (
      <DashboardLayout companyId={this.props.match.params.companyId} token={token} heading="Datos de la Empresa">
        {this.renderNavigation()}

        <Row className="mt-3">
          <Col md={3}>
            <img src={`/core/${company.photo}`} alt="" width="100%"/>
            {
              canAdmin ?
                <React.Fragment>
                  <hr/>
                  <p/>
                  <input type="file" onChange={this.handleFileChange} accept="image/x-png,image/gif,image/jpeg"/>
                  <Button color="secondary" size="sm" onClick={this.handleSubmitPhoto} block
                          className="mt-2 mb-4">Editar</Button>
                </React.Fragment>
                : null
            }
          </Col>

          <Col md={9}>
            <Formik
              initialValues={this.state.company}
              enableReinitialize={true}
              onSubmit={this.handleSubmit}
              validationSchema={companyForm.validation}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput label="Rut" name="rut" formikProps={props} disabled/>
                  <FormikInput label="Razón Social" name="businessName" formikProps={props} disabled/>
                  <FormikInput label="Giro" name="category" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Dirección" name="address" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Nombre Fantasía" name="name" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Unidad Principal" name="mainUnit" formikProps={props} disabled={!canAdmin}/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Guardar cambios</Button>
                  </div>
                </form>
              }
            </Formik>
          </Col>
        </Row>
      </DashboardLayout>
    )
  }

  renderNavigation () {
    const { companyId } = this.props.match.params
    const { location } = this.props

    return (
      <ButtonNavigation links={[
        { to: `/companies/${companyId}/detail`, text: 'Datos Empresa' },
      ]} location={location}/>
    )
  }
}

export default CompanyDetail
