export const ENDPOINT_COMPANY_CREATE = 'https://bhp-usuarios.dev.lifeon.cl/core/companies'
export const ENDPOINT_COMPANIES = 'https://bhp-usuarios.dev.lifeon.cl/core/companies'
export const ENDPOINT_USERS = 'https://bhp-usuarios.dev.lifeon.cl/core/users'
export const ENDPOINT_DOCUMENT_DEFINITIONS = 'https://bhp-usuarios.dev.lifeon.cl/documents/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = 'https://bhp-usuarios.dev.lifeon.cl/documents/submissions'
export const ENDPOINT_SII = 'https://bhp-usuarios.dev.lifeon.cl/sii'
export const ENDPOINT_PERMISSIONS = 'https://bhp-usuarios.dev.lifeon.cl/permissions'

/*
export const ENDPOINT_COMPANY_CREATE = 'http://localhost:4000/companies'
export const ENDPOINT_COMPANIES = 'http://localhost:4000/companies'
export const ENDPOINT_USERS = 'http://localhost:4000/users'
export const ENDPOINT_DOCUMENT_DEFINITIONS = 'http://localhost:5000/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = 'http://localhost:5000/submissions'
*/
