import React from 'react'
import PropTypes from 'prop-types'
import styles from './Box.module.scss'

export default class Box extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtext: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }

  render() {
    return (
      <div className={styles.container} style={this.props.style}>
        {this.renderHeader()}

        {this.props.children}
      </div>
    )
  }

  renderHeader () {
    const { title, subtext } = this.props

    if (!title && !subtext) {
      return null
    }

    return (
      <div className={styles.padded}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    )
  }
}
