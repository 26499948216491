import * as Yup from 'yup'

export const fields = [
  { label: 'Rut', name: 'rut' },
  { label: 'Razón Social', name: 'businessName' },
  { label: 'Giro', name: 'category' },
  { label: 'Dirección', name: 'address' },
  { label: 'Nombre Fantasía', name: 'name' },
  { label: 'Unidad Principal', name: 'mainUnit' },
]

export const validation = Yup.object().shape({
  businessName: Yup.string().required('Requerido'),
  rut: Yup.string().required('Requerido'),
  category: Yup.string().required('Requerido'),
  address: Yup.string().required('Requerido'),
  name: Yup.string().required('Requerido'),
  mainUnit: Yup.string().required('Requerido'),
})
